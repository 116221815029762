import React, { useEffect } from "react"
import { navigate } from "gatsby"

const EdgeNetworkingRedirectionPage = () => {
  useEffect(() => {
    navigate("/solutions/edge/edge-networking-and-security")
  }, [])

  return <></>
}

export default EdgeNetworkingRedirectionPage
